import React, {useEffect, useState} from 'react';
import EinloggenView from './einloggenView';
import {useNavigate} from "react-router-dom";
import { loginUser } from '../loginUserAWS';



const Einloggen: React.FC = () => {

    const navigate = useNavigate();


    const [nutzer, setNutzer] = useState({

        password: ''
    })

    const einmelden = (e: React.ChangeEvent<HTMLInputElement>) => {

        const {name, value} = e.target;
        setNutzer((antes) => ({
            ...antes, 
            [name]: value
        }))
    }

    const setLocalStorageItem = (key: string, value: string) => {

        localStorage.setItem(key, value);
    }
  
    const setTokens = async ()  => {
        try {
        if(process.env.REACT_APP_COGNITO_USER && process.env.REACT_APP_PASSWORD_COGNITO) {
            
       const { accessToken, idToken } = await loginUser(process.env.REACT_APP_COGNITO_USER, process.env.REACT_APP_PASSWORD_COGNITO);
       await  sessionStorage.setItem('accessToken', accessToken);
       await sessionStorage.setItem('idToken', idToken);

       
        }
     } catch (err) {
        console.log({message: `setTokensError`, err, })
        
     } } 



    const verify = async (e: React.FormEvent<HTMLFormElement>) => {
       
  
        const normal = "normal";
        const admin = "admin";
        setLocalStorageItem("nutzer", nutzer.password);
        if( nutzer.password === 'Maranatha') {


          await navigate("/start", { state: { normal }  });
           
        }

        else if (nutzer.password === 'admin') {

            await navigate("/start", {state: { admin }});
        }
    }

    useEffect(() => {

        setTokens()
     }, [nutzer.password === "admin"])

    return <EinloggenView einmelden={einmelden} verify={verify}/>
}




export default Einloggen
import {CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import userPool from './amazon-cognito-identity';
import { useEffect } from "react";

interface AuthResult {
    accessToken: string;
    idToken: string;
    refreshToken: string;
  }


export const loginUser = async (username: string, password: string):Promise<AuthResult> => {

    return new Promise((resolve, reject) => {
        const userData = {
            Username: username, 
            Pool: userPool
        }
  

        const cognitoUser =  new CognitoUser(userData);
        const authDetails = new AuthenticationDetails({
            Username: username, 
            Password: password
        })

        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (result) => {
                const tokens: AuthResult = {
                    accessToken: result.getAccessToken().getJwtToken(),
                    idToken: result.getIdToken().getJwtToken(),
                    refreshToken: result.getRefreshToken().getToken()
                    
                }

                storeTokens(tokens);
                resolve(tokens)
                sessionStorage.setItem('username', username)
            },
            onFailure: (err) => {
                console.error('Fehler bei der Anmeldung', err);
                reject(err)
            }

           
        })
    

});}

const getCognitoUser = (): CognitoUser | null => {

    const username = localStorage.getItem('username');
    if(!username) {
        console.error('kein benutzername gespeichert');
        return null
    }

    const userData = {
        Username: username,
        Pool: userPool
    }

    return new CognitoUser(userData)
}


const storeTokens = (tokens: AuthResult) => {
    sessionStorage.setItem('accessToken', tokens.accessToken);
    sessionStorage.setItem('idToken', tokens.idToken)
    sessionStorage.setItem('refreshToken', tokens.refreshToken)
}


const refreshTokens = () => {
    const cognitoUser = getCognitoUser();

    if(!cognitoUser) {
    return Promise.reject('Kein Cognitobenutzer gefunden');
    
    }

    const refreshToken = cognitoUser.getSignInUserSession()?.getRefreshToken();


    if(!refreshToken) {
      return Promise.reject('Kein Refresh-token gefunden')
    }
   
    return new Promise<AuthResult>((resolve, reject) => {
       

        cognitoUser.refreshSession(refreshToken, (err, session) => {
            if(err) {
                reject('Fehler beim erneuern des Tokens')
            } else {
                const tokens: AuthResult = {
                    accessToken: session.getAccessToken().getJwtToken(),
                    idToken: session.getIdToken().getJwtToken(),
                    refreshToken: session.getRefreshToken().getToken()
                };
                
                console.log('Tokens erfolgreich erneuert');
                storeTokens(tokens)
                resolve(tokens)
            }
        })
    })

      
}

export const checkAndRefreshToken = (): Promise<AuthResult> => {
    const accessToken = sessionStorage.getItem('accessToken');
    
    if(!accessToken) {
        return Promise.reject('Kein Access-Token gefunden. Benutzer muss sich anmelden')
    }
    const expirationTime = getTokenExpirationTime(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    if(currentTime > expirationTime) {
        console.log('Access-Token abgelaufen, versuche zu erneuern');
        return refreshTokens();
        
    } else {
        console.log('Access-Token ist noch gueltig.');
        return Promise.resolve({
            accessToken,
            idToken: sessionStorage.getItem('idToken') || '',
            refreshToken: sessionStorage.getItem('refreshToken') || '',
        })
    }
}

const getTokenExpirationTime = (token: string): number => {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp;
};


 
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { AWS_COGNITO_CONFIG } from './awsConfig';



 const userPool = new CognitoUserPool({

   
    UserPoolId: AWS_COGNITO_CONFIG.UserPoolId ? AWS_COGNITO_CONFIG.UserPoolId : "",
    ClientId: AWS_COGNITO_CONFIG.ClientId ? AWS_COGNITO_CONFIG.ClientId : ""


 }); 

 export default userPool;

 